.nav-mini .accordion {
  border: none;
}

.nav-mini .accordion .accordion-body {
  color: var(--white);
  padding: 0;
}

.nav-mini .accordion .accordion-item {
  border: none;
}

.nav-mini .accordion .accordion-item li {
  text-transform: capitalize;
}

.nav-mini .accordion-button:not(.collapsed) {
  box-shadow: none;
}
.nav-mini .accordion .accordion-item .accordion-button {
  border: none;
  color: var(--white);
  background-color: var(--black);
}

.nav-mini .accordion .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.nav-mini .accordion-button .page-title::before {
  transition: transform 0.2s ease-out;
  -webkit-transition: transform 0.2s ease-out;
  -moz-transition: transform 0.2s ease-out;
  -ms-transition: transform 0.2s ease-out;
  -o-transition: transform 0.2s ease-out;
}
.nav-mini .accordion-button:not(.collapsed) .page-title::before {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}
.nav-mini .accordion .accordion-item .accordion-button::after {
  display: none;
}

.nav-mini .accordion,
.nav-mini .accordion .accordion-item {
  background-color: var(--black);
}


