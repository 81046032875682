.banner-top {
  position: relative;
}

.banner-top-title {
  position: absolute;

  top: 0;
  left: 5%;
}
.banner-top-title h2 {
  max-width: 40vw;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  font-style: italic;
}
.banner-top-icon {
  position: absolute;
  bottom: -4%;
  right: 2%;
}
.banner-top-icon {
  width: 10vw;
}

.banner-top-link {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.banner-top-link a {
  display: block;
}
.banner-top-link a:first-of-type {
  letter-spacing: 3px;
  margin-bottom: 1vh;
  text-transform: capitalize;
  font-weight: 500;
  color: var(--black);
}
.banner-top-link a:last-of-type {
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: capitalize;
  margin-bottom: 1vh;
}

.banner-top-link-gif {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  min-width: 50vw;
}

.banner-top-gif {
  margin-top: 1vh;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-home1-bottom {
  background: url(../../../images/3.png) no-repeat scroll center top;
}
.banner-home1-bottom .page-title {
  border-bottom: none;
  text-transform: none;
  letter-spacing: 1;
  font-weight: normal;
  color: var(--black);
}

.banner-home1-bottom .fabrika {
  transform: rotate(-30deg);
  -webkit-transform: rotate(-30deg);
  -moz-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  -o-transform: rotate(-30deg);
}

.banner-home1-bottom .text ul li {
  font-size: 21px;
  color: #202020;
  font-weight: 400;
  list-style-type: disc;
  line-height: 30px;
  list-style-position: inside;
  margin: 10px 0px;
}

.banner-home1-bottom .title-text h3,
.banner-home1-bottom .text-div-main {
  font-size: 38px;
}

.banner-home1-bottom .text-div-main {
  margin-right: 30px;
}

.text-div-main {
  text-align: center;
  font-size: 45px;
  color: #212121;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
}
.text-effect-div {
  display: inline-block;
  overflow: hidden;
  /* white-space: nowrap; */
}
.text-effect-div:first-of-type {
  animation: showup 7s infinite;
}
.text-effect-div:last-of-type {
  width: 0px;
  animation: reveal 7s infinite;
}

@keyframes showup {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
    width: 0px;
  }
  20% {
    opacity: 1;
    width: 0px;
  }
  30% {
    width: 270px;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 270px;
  }
}

@media (max-width: 996px) {
  .banner-top-title h2 {
    font-size: 24px;
  }
  .banner-home1-bottom .title-text h3,
  .banner-home1-bottom .text-div-main {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .banner-home1-bottom .text-div-main {
    margin-right: 0px;
  }
  .banner-home1-bottom .title-text h3,
  .banner-home1-bottom .text-div-main {
    font-size: 34px;
  }
  .banner-top-title h2 {
    font-size: 16px;
  }
  .banner-top-icon {
    bottom: 5%;
  }
  .banner-top-icon img {
    width: 11vw;
  }
}

@media (max-width: 576px) {
  .banner-home1-bottom .title-text h3,
  .banner-home1-bottom .text-div-main {
    font-size: 36px;
  }

  .banner-top-icon img {
    width: 12vw;
  }
}
