footer {
  /* background-color: var(--gray); */
}
footer .list .page-title {
  color: var(--black);
}
footer .copy-rights {
  font-weight: 300;
  color: var(--black);
  letter-spacing: 2px;

  font-size: 0.9em;
}

.list ul li {
  line-height: 1.8rem;
}

footer .icons a svg:hover {
  opacity: 1;
  fill: var(--gold) !important;
}

footer .list ul li a {
  letter-spacing: 0px;
  font-size: 1em;
  color: var(--black) !important;
}
footer .list .icons svg {
  fill: var(--black) !important;
}

footer .osi:hover {
  color: var(--gold) !important;
}

footer list li {
  letter-spacing: 1px;
}

footer .nav-mini .accordion,
footer .nav-mini .accordion .accordion-item,
footer .nav-mini .accordion .accordion-item .accordion-button {
  background-color: #f8f9fa;
}
footer .nav-mini .languages span:first-child {
  color: var(--gold);
}
footer .nav-mini .languages span,
footer .nav-mini .accordion .accordion-body {
  color: var(--black);
}

footer .mouse_scroll {
  transform: rotate(180deg);
}

.footer .scroll {
  overflow: scroll;
}
footer select {
  background: #f8f9fa !important;
}
