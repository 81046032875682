.primary-button button {
  border: 1px solid #b78b39;
  border-radius: 21vh;
}

.primary-button button:hover {
  background-color: #b78b39;
  color: var(--white);
}
.section-header::first-letter {
  color: var(--gold);
}
.section-header,
.section-header-sub {
  letter-spacing: 2px;
  font-weight: 500;
  color: var(--black);
}
.section-header-sub {
  margin-top: 14px;
  font-weight: 400;
}

.section-header::first-letter {
  color: var(--gold);
  border-bottom: 1px solid var(--gold);
}

.title-text h3,
.title-text text {
  font-weight: 700;
  font-size: 42px;
  color: #212121;
  
}
.title-text h3 span {
  color: var(--gold);
}

.m_scroll_arrows {
  display: block;

  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);

  border-right: 2px solid gray;
  border-bottom: 2px solid gray;
  margin: 0 0 6px 6px;

  width: 12px;
  height: 12px;
}

.one {
  margin-top: 1px;
}

.one,
.two,
.three {
  animation: mouse-scroll 1s infinite;
  -webkit-animation: mouse-scroll 1s infinite;
  -moz-animation: mouse-scroll 1s infinite;
}

.one {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  animation-delay: alternate;
}

.two {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;

  animation-delay: 0.2s;
  animation-direction: alternate;
  margin-top: -6px;
}

.three {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;
  animation-delay: 0.3s;
  animation-direction: alternate;
  margin-top: -6px;
}

.page-title {
  color: var(--white);
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 1px solid var(--gold);
  padding-left: 1.2em;
  letter-spacing: 1px;
}

.btn-primary {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: #fff !important;
  --bs-btn-border-color: #fff !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #fff !important;
  --bs-btn-hover-border-color: #fff !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: #fff !important;
  --bs-btn-disabled-bg: #fff !important;
  --bs-btn-disabled-border-color: #fff !important;
}

.page-title::before {
  content: "";
  border-color: transparent var(--gold);
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  left: -1em;
  top: 1em;
  position: relative;
}

.list ul {
  padding: 5px;
}

.list ul li {
  line-height: 2em;
}

.list .icons a svg {
  fill: white;
  width: 36px !important;
  height: 36px !important;
}
.list .icons a:last-child svg {
  width: 28px !important;
  height: 28px !important;
  margin-bottom: 10px !important;
}

.list .icons a svg:hover,
.list .icons a img:hover {
  opacity: 0.5;
}
.list ul li a {
  color: var(--white) !important;
  text-transform: capitalize;
  letter-spacing: 2px;
}

.list ul li a:hover {
  color: var(--grayish-white) !important;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--gold);
}

.triangle-button {
  color: var(--gold);
}

.cursor-pointer {
  cursor: pointer;
}
.visit {
  color: var(--gray);
  letter-spacing: 1.5;
}
.pagination-button {
  font-size: 1.1em;
  color: var(--black);
  border: 1px solid var(--grayish-white);
  margin: 5px;
  padding: 3px 10px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.pagination-button.active,
.pagination-button:hover {
  background-color: var(--gold);
  border-color: var(--gold);
  color: var(--white);
}

.text-initial {
  color: var(--gold);
}

.date {
  font-size: 0.9em;
  color: var(--grayish-white);
  font-style: italic;
}

.buttons button {
  color: var(--gold);
  font-size: 20px;
  cursor: pointer;
}
.buttons button:disabled span,
.buttons span:hover {
  color: var(--grayish-white) !important;
}

.mt-70 {
  margin-top: -70px;
}

.text-content {
  font-size: 1.25rem;
  letter-spacing: 1px;
  line-height: 1.5;
}
.bold {
  color: var(--gold);
}

.mr-10 {
  margin-left: 10px;
}

.mt-15 {
  margin-top: 15px !important;
}
.pb-50 {
  padding-bottom: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

@media only screen and (max-width: 576px) {
  .text-content {
    font-size: 1.15rem;
    letter-spacing: 1px;
    line-height: 1.4;
  }
}

.scroll-top {
  scroll-margin-top: 8vh !important;
}

.first-letter::first-letter {
  color: var(--gold);
  border-bottom: 1px solid var(--gold);

}


