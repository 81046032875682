.custom-carousel-wrapper {
  background-color: #ededed;

  position: relative;
}
.custom-carousel {
  padding-top: 5vh;
}

.custom-carousel .owl-nav {
  margin-top: 5vh;
  margin-bottom: 5vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  background-image: url(../../../../../public/assets/images/mag-stor-shine.gif);
  background-position: center;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  height: 100px;
  cursor: pointer;
}

.custom-carousel svg {
  max-height: 250px;
}
.custom-carousel .m_scroll_arrows {
  display: block;
  margin: 0px;
  width: 16px;
  height: 16px;
}

.custom-carousel .mouse_scroll.next .m_scroll_arrows {
  transform: rotate(-45deg) translateY(-200%);
  -ms-transform: rotate(-45deg) translateY(-200%);
  -webkit-transform: rotate(-45deg) translateY(-200%);
  -moz-transform: rotate(-45deg) translateY(-200%);
  -o-transform: rotate(-45deg) translateY(-200%);
}
.custom-carousel .mouse_scroll.prev .m_scroll_arrows {
  transform: rotate(-225deg) translateY(200%);
  -ms-transform: rotate(-225deg) translateY(200%);
  -webkit-transform: rotate(-225deg) translateY(200%);
  -moz-transform: rotate(-225deg) translateY(200%);
  -o-transform: rotate(-225deg) translateY(200%);
}

.custom-carousel .arrow-wrapper {
  display: flex;
  justify-content: center;
  width: 750px;
}
.custom-carousel-wrapper > img {
  position: absolute;
  width: 150px;
  left: 50%;
  transform: translateX(-52.6%);
  -webkit-transform: translateX(-52.6%);
  -moz-transform: translateX(-52.6%);
  -ms-transform: translateX(-52.6%);
  -o-transform: translateX(-52.6%);
  bottom: 1%;
}

.custom-carousel-wrapper .product-name {
  letter-spacing: 1px;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: transparent !important ;
}

@media screen and (max-height: 1080) {
  .custom-carousel .owl-nav {
    margin-top: 5vh;
    height: 100px;
  }
}

@media screen and (max-width: 992px) {
  .custom-carousel .m_scroll_arrows {
    display: none;
  }
  .custom-carousel .owl-nav {
    padding-top: 30px;
    height: 100px;
    /* background-color: red; */
  }
}

#home_categories {
  background-color: #ededed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: auto;
}

#home_categories
  .categories-carousel
  .owl-stage
  .owl-item:last-child
  .item-position-container
  svg.item-line {
  display: none;
}

#home_categories .owl-stage-outer {
  overflow: visible !important;
}

#home_categories .title_area {
  margin-left: 6.228vw !important;
}

@media (max-width: 1200px) {
  #home_categories .title_area {
    transform: translateY(-10vw);
    -webkit-transform: translateY(-10vw);
    -moz-transform: translateY(-10vw);
    -ms-transform: translateY(-10vw);
    -o-transform: translateY(-10vw);
  }
}

@media (max-width: 1200px) and (max-width: 768px) {
  #home_categories .title_area {
    transform: translateY(-15vw);
  }
}

#home_categories .title_padding {
  padding-top: 3rem;
}

#home_categories * {
  transition: transform 0.25s ease-in-out;
}

#home_categories .owl-nav {
  padding-top: 50px;
}

#home_categories .owl-nav.disabled {
  display: flex !important;
}
.owl-item {
  max-height: 400px !important;
}
.icon.iconslider img {
  height: 50px !important;
}
#home_categories .owl-item .icon {
  transform: scale(0.4);
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  -ms-transform: scale(0.4);
  -o-transform: scale(0.4);
}

#home_categories .owl-item .upper-name {
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  line-height: 0;
  transform: scaleY(0) translateY(-2vh);
  transform-origin: center;
}

#home_categories .owl-item .lower-name {
  transform: scaleY(1);
}

#home_categories .owl-item.center .icon {
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
}

#home_categories .owl-item.center .upper-name {
  transform: scaleY(1) translateY(-13vh);
  -webkit-transform: scaleY(1) translateY(-13vh);
  -moz-transform: scaleY(1) translateY(-13vh);
  -ms-transform: scaleY(1) translateY(-13vh);
  -o-transform: scaleY(1) translateY(-13vh);
}

#home_categories .owl-item.center .lower-name {
  transform: scaleY(0);
}

#home_categories .category-carousel-item a {
  text-decoration: none !important;
}

#home_categories .category-carousel-item .item-lower-name {
  text-decoration: none !important;
}

#home_categories .category-carousel-item .item-lower-name span {
 
  text-decoration: none !important;
  color: #171717 !important;
  /* font-size: 1.2rem !important; */ 
}

#home_categories .category-carousel-item svg * {
  /* fill: #393636; */
}

#home_categories .category-carousel-item .item-position-container > svg {
  z-index: -1;
  width: 100%;
}

#home_categories .category-carousel-item .item-position svg {
  width: 8px;
  height: 8px;
}

#home_categories .category-carousel-item .item-position svg circle {
  /* 
   */
}

/* 
*/
.owl-carousel .owl-stage {
  position: relative;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-dot {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.carousel {
  position: relative;
  width: 100%;
  height: 100vh;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.no-js .owl-carousel {
  display: block;
}

.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height 500ms ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item .owl-lazy[src^=""],
.owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

/* .owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}
 */
.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}
.home-demo .item {
  background: #ff3f4d;
}
.home-demo h2 {
  color: #fff;
  text-align: center;
  padding: 5rem 0;
  margin: 0;
  font-style: italic;
  font-weight: 300;
}

/*****************************************************************************************************/

#home_categories * {
  transition: transform 0.25s ease-in-out;
}

.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel {
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
}

#home_categories .owl-stage-outer {
  overflow: visible !important;
}
#home_categories * {
  transition: transform 0.25s ease-in-out;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0px, 0px, 0px);
}

#home_categories * {
  overflow: visible !important;
  transition: transform 0.25s ease-in-out;
}
.owl-carousel .owl-stage {
  position: relative;
  touch-action: manipulation;
}

#home_categories .category-carousel-item .item-position img {
  width: 100%;
  height: 100%;
  fill: #ededed;
  stroke: #171717;
  stroke-width: 4;
  z-index: 2;
}

#home_categories .category-carousel-item .item-position-container > img {
  z-index: -1;
  width: 100%;
}

#home_categories .category-carousel-item .item-position img {
  width: 30px;
  height: 30px;
  border: 2px solid #000;
  border-radius: 100% !important;
}

#home_categories .category-carousel-item .item-position img circle {
  width: 100%;
  height: 100%;
  fill: #ededed;
  stroke: #171717;
  stroke-width: 4;
  z-index: 2;
}

#home_categories .category-carousel-item .item-position-container > img {
  z-index: -1;
  width: 100%;
}

#home_categories .category-carousel-item .item-position img {
  width: 30px;
  height: 30px;
  border: 2px solid #000;
  border-radius: 100% !important;
}
#home_categories .category-carousel-item .item-position img circle {
  width: 100%;
  height: 100%;
  fill: #ededed;
  stroke: #171717;
  stroke-width: 4;
  z-index: 2;
}

.carousel-container .owl-item .category-carousel-item .item-position-container {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-container
  .owl-item
  .category-carousel-item
  .item-position-container
  .position_line {
  position: absolute;
  width: calc(100% + 8rem);
  height: 1px;
  background: #000;
  z-index: 0;
}

.carousel-container
  .owl-item
  .category-carousel-item
  .item-position-container
  .item-position {
  z-index: 1;
  background: #000;
  border-radius: 100px;
  height: 20px;
  width: 20px;
}

.carousel-container
  .owl-item
  .category-carousel-item
  .item-position-container
  .item-position
  .text {
  display: none;
}

.carousel-container
  .owl-item:first-child
  .category-carousel-item
  .item-position-container
  .position_line,
.carousel-container
  .owl-item:last-child
  .category-carousel-item
  .item-position-container
  .position_line {
  width: calc(50% + 4rem);
  z-index: 0;
}

.carousel-container
  .owl-item:first-child
  .category-carousel-item
  .item-position-container
  .position_line {
  right: -4rem;
}

.carousel-container
  .owl-item:last-child
  .category-carousel-item
  .item-position-container
  .position_line {
  left: -4rem;
}

#category_products a,
#sector_products a {
  text-decoration: unset;
}

#category_products a:hover,
#sector_products a:hover {
  text-decoration: unset;
  color: initial;
}

#category_products .item-position-container,
#sector_products .item-position-container {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#category_products .item-position-container .item-position,
#sector_products .item-position-container .item-position {
  z-index: 1;
  background: rgb(27, 27, 27);
  border-radius: 100px;
  height: 20px;
  width: 20px;
}

#category_subcategories .owl-item .category-carousel-item .item-upper-name,
#brand_subcategories .owl-item .category-carousel-item .item-upper-name {
  visibility: hidden;
  height: 35px;
}

#category_subcategories
  .owl-item.active.center
  .category-carousel-item
  .item-upper-name,
#brand_subcategories
  .owl-item.active.center
  .category-carousel-item
  .item-upper-name {
  visibility: visible;
}

#home_categories .owl-item .upper-name {
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  line-height: 0;
  transform: scaleY(0) translateY(-2vh);
  transform-origin: center;
}

#home_categories .owl-item .upper-name .item-upper-name span {
  color: #fff;
  font-size: 1.2em;
  padding: 3.114vw 4.152vw;
  display: inline-block;
  transform: translateY(-10px);
}

.owl-item.active.center svg polygon {
  fill: var(--gold) !important;
}

#home_categories
  .owl-item.active.center
  .category-carousel-item
  .item-position
  svg {
  width: 9px;
  height: 9px;
}

@media screen and (max-width: 1920px) {
  .carousel {
  
    height: auto;
  }
}

@media screen and (max-width: 1200px) {
  #home_categories .owl-item .upper-name .item-upper-name span {
    font-size: 18px;
  }
}
.custom-carousel .product-name {
  font-size: 0.8em;
}

@media screen and (max-width: 992px) {
  .custom-carousel .product-name {
    font-size: 0.6em;
  }
  #home_categories .category-carousel-item .item-position svg {
    width: 6px;
    height: 6px;
  }
  #home_categories
    .owl-item.active.center
    .category-carousel-item
    .item-position
    svg {
    width: 7px;
    height: 7px;
  }

 
}

@media (max-width: 769px) {
  .custom-carousel svg {
    max-height: 200px;
  }

.item-lower-name{
  font-size: 19px;
}

}

@media (max-width: 568px) {
  .custom-carousel svg {
    max-height: 150px;
  }


  .custom-carousel .owl-nav {
    margin-bottom: 3vh;
    margin-top: 2vh;
   
  }
  



} 
