.home .information {
  padding: 3rem 0;
}

.home .demands {
  max-width: 220px;
}
.home .inner {
  position: relative;
}
.home .information .text-inner {
  background: var(--white);
  color: var(--black);
  padding: 25px 17px;
  position: absolute;
  bottom: 12%;
  left: 5%;
  font-size: 0.9rem;
  width: 20vw;
  height: 150px;
}

.home--card {
  position: relative;

  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.014),
    0 6.7px 5.3px rgba(0, 0, 0, 0.02), 0 12.5px 10px rgba(0, 0, 0, 0.025),
    0 22.3px 17.9px rgba(0, 0, 0, 0.03), 0 41.8px 33.4px rgba(0, 0, 0, 0.036),
    0 100px 80px rgba(0, 0, 0, 0.05);
}
.card--image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.card--image img {
  width: 140%;
  height: 140%;
  object-fit: cover;
}

.card--info {
  --size: 20px;
  width: var(--size);
  height: var(--size);
  background: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  position: absolute;
  transition: width 550ms ease, height 550ms ease, right 550ms ease,
    background 550ms ease;
  overflow: hidden;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.014),
    0 6.7px 5.3px rgba(0, 0, 0, 0.02), 0 12.5px 10px rgba(0, 0, 0, 0.025),
    0 22.3px 17.9px rgba(0, 0, 0, 0.03), 0 41.8px 33.4px rgba(0, 0, 0, 0.036),
    0 100px 80px rgba(0, 0, 0, 0.05), 0 0px 2.2px rgba(0, 0, 0, 0.045),
    0 0px 5.3px rgba(0, 0, 0, 0.065), 0 0px 10px rgba(0, 0, 0, 0.08),
    0 0px 17.9px rgba(0, 0, 0, 0.095), 0 0px 33.4px rgba(0, 0, 0, 0.115),
    0 0px 80px rgba(0, 0, 0, 0.16), 0 0.2px 2.2px rgba(0, 0, 0, 0.014),
    0 0.6px 5.3px rgba(0, 0, 0, 0.02), 0 1.1px 10px rgba(0, 0, 0, 0.025),
    0 2px 17.9px rgba(0, 0, 0, 0.03), 0 3.8px 33.4px rgba(0, 0, 0, 0.036),
    0 9px 80px rgba(0, 0, 0, 0.05);
}

.card--info::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0.25) 50%,
    transparent 100%
  );
  z-index: 1;
  opacity: 0;
  transition: opacity 550ms ease;
}

.home-card .info--1 {
  bottom: 47%;
  right: 80%;
}

.info--image img {
  top: -80%;
  left: -20%;
}

.home-card .info--2 {
  bottom: 25%;
  right: 15%;
}

.info--image img {
  right: -20%;
  bottom: -80%;
}

.info--text {
  display: block;
  position: absolute;
  left: 10%;
  bottom: 5%;
  font-size: 0.55rem;
  letter-spacing: 1px;
  font-family: "Montserrat";
  font-weight: bold;
  color: #fff;
  white-space: nowrap;
  z-index: 2;
  opacity: 0;
  transform: translateY(25%);
  transition: opacity 250ms ease, transform 250ms ease;
}

.manufacturing_gif{
  width: 300px;
}

.info--image {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.info--image img {
  position: absolute;
  width: 340%;
  height: 340%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 550ms ease;
}

.home .youtube-section .card {
  height: auto !important;
  width: auto !important;
}

.home .card-wrapper {
  padding-top: 0 !important;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.zoom-card {
  position: relative;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.014),
    0 6.7px 5.3px rgba(0, 0, 0, 0.02), 0 12.5px 10px rgba(0, 0, 0, 0.025),
    0 22.3px 17.9px rgba(0, 0, 0, 0.03), 0 41.8px 33.4px rgba(0, 0, 0, 0.036),
    0 100px 80px rgba(0, 0, 0, 0.05);
}

.zoom-card--info {
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  position: absolute;
  transition: width 550ms ease, height 550ms ease, right 550ms ease,
    background 550ms ease;
  overflow: hidden;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.014),
    0 6.7px 5.3px rgba(0, 0, 0, 0.02), 0 12.5px 10px rgba(0, 0, 0, 0.025),
    0 22.3px 17.9px rgba(0, 0, 0, 0.03), 0 41.8px 33.4px rgba(0, 0, 0, 0.036),
    0 100px 80px rgba(0, 0, 0, 0.05), 0 0px 2.2px rgba(0, 0, 0, 0.045),
    0 0px 5.3px rgba(0, 0, 0, 0.065), 0 0px 10px rgba(0, 0, 0, 0.08),
    0 0px 17.9px rgba(0, 0, 0, 0.095), 0 0px 33.4px rgba(0, 0, 0, 0.115),
    0 0px 80px rgba(0, 0, 0, 0.16), 0 0.2px 2.2px rgba(0, 0, 0, 0.014),
    0 0.6px 5.3px rgba(0, 0, 0, 0.02), 0 1.1px 10px rgba(0, 0, 0, 0.025),
    0 2px 17.9px rgba(0, 0, 0, 0.03), 0 3.8px 33.4px rgba(0, 0, 0, 0.036),
    0 9px 80px rgba(0, 0, 0, 0.05);
}

.zoom-card--info .info--image img {
  position: absolute;
  width: 450%;
  height: 450%;
  object-fit: cover;
  /* opacity: 0.9 !important; */
  transition: opacity 550ms ease;
  z-index: 2;
}
.zoom-card:hover .zoom-card--info {
  height: 350px;
  width: 350px;
  border-radius: 0.5em;
  -webkit-border-radius: 0.5em;
  -moz-border-radius: 0.5em;
  -ms-border-radius: 0.5em;
  -o-border-radius: 0.5em;
}

.zoom-card--info.info--1 .info--image img {
  left: -10%;
}
.zoom-card--info.info--2 {
  /* background-color: red; */
  bottom: 25% !important;
  left: 25% !important;
}
.zoom-card--info.info--2 .info--image img {
  left: -30%;
  bottom: -120%;
}

.zoom-card:hover .zoom-card--info .info--image img {
  opacity: 1;
}

.info--image img {
  position: absolute;
  width: 340%;
  height: 340%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 550ms ease;
}
/* burasi */
.zoom-card--info.info--1 .info--image img {
  top: -350%;
  left: -120%;
}
.zoom-card--info .info--image img.img1 {
  width: 900%;
  height: 900%;
}
.zoom-card--info .info--image img.img2 {
  width: 400%;
  height: 400%;
}
@media (min-width: 1400px) {
  .home .youtube-section iframe {
    width: 1280px;
    height: 500px;
  }
}

@media (max-width: 1400px) {
  .home .youtube-section iframe {
    width: 950px;
    height: 400px;
  }
  .zoom-card:hover .zoom-card--info {
    height: 300px;
    width: 300px;
  }
}


@media (max-width: 1200px) {
  .zoom-card:hover .zoom-card--info {
    height: 220px;
    width: 220px;
  }
}

@media (max-width: 968px) {
  .home .youtube-section iframe {
    width: 600px;
    height: 300px;
  }
 
}

@media (max-width: 769px) {
  .home .youtube-section iframe {
    width: 400px;
    height: 200px;
  }
  .zoom-card:hover .zoom-card--info {
    height: 150px;
    width: 150px;
  }

}

@media (max-width: 576px) {
  .zoom-card:hover .zoom-card--info {
    height: 100px;
    width: 100px;
  }

  .manufacturing_gif{
    width: 250px;
  }
}


@media (max-width: 490px) {
  .home .youtube-section iframe {
    width: 300px;
    height: 200px;
  }
  
}


