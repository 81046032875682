.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 2em;
  box-sizing: border-box;
  background: #000;
}

.loader img{
  width: 5%;
}

.loader small {
  opacity: 0.4;
  margin-top: 20px;
  color: var(--grayish-white);
}

/* width="200px" height="200px"  */
.loader svg {
  width: 200px;
  height: 200px;
}
.loader svg polygon {
  stroke-dasharray: 50;

  animation: dashoffset 1.5s linear infinite;
}

@keyframes dashoffset {
  to {
    stroke-dashoffset: 100;
  }
}
@media screen and (max-width: 992px) {
  .loader svg {
    width: 100px;
    height: 100px;
    /* stroke-dasharray: 30;
    animation: dashoffset 0.5s linear infinite;
    -webkit-animation: dashoffset 0.5s linear infinite; */
  }
  .loader small {
    font-size: 16px;
  }
}
