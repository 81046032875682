:root {
  --white: #fff;
  --grayish-white: #bcbcbd;
  --black: #1d1d1d;
  --gold: #DF4513;
  --gray: #eaeaea;
  --medium-gray: rgb(29, 29, 29);
  --dark-gray: #121214;
  --navBarHeight: 10vh;
}
