.cookie {
  position: fixed;
  bottom: 0;
  left: 0;
  /* height: 100vh; */
  width: 100%;
  /* background: var(--black); */

  z-index: 1000;
}
.cookie .first-part {
  height: 70vh;
  background-color: rgba(0, 0, 0, 0.5);
}
.cookie .second-part {
  height: 30vh;
  background-color: var(--black);
}
.cookie .cookie-text {
  color: var(--white);
}
.cookie .cookie-text a {
  color: var(--gold) !important;
}
.cookie button {
  background-color: var(--gold);
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.cookie button:hover {
  background-color: var(--gold);
  outline: none !important;
  box-shadow: none !important;
}
