.nav-toggler {
  position: fixed;
  width: 100%;
  z-index: 1002;
  left: 100%;
  top: 5%;
  height: 100vh;
  transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  overflow: scroll;
  padding-bottom: 10vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.nav-toggler::-webkit-scrollbar {
  display: none;
}

.nav-toggler.active {
  left: 0%;
  transition: all 0.7s ease-in-out 0.1s;
  -webkit-transition: all 0.7s ease-in-out 0.1s;
  -moz-transition: all 0.7s ease-in-out 0.1s;
  -ms-transition: all 0.7s ease-in-out 0.1s;
  -o-transition: all 0.7s ease-in-out 0.1s;
}

.nav-toggler .page-title {
  font-size: 1.25em;
}

.nav-toggler ul li a {
  letter-spacing: 0px;
  font-size: 1.25em;
  text-transform: capitalize !important;
}
.languages span {
  color: var(--white);
  font-weight: 500;
  font-size: 19px;
  margin: 0 10px;
}

.languages span:first-child {
  color: var(--gold);
}
