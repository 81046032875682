@import "./abstracts/variables.css";
@import "./abstracts/mixins.css";
@import "./abstracts/functions.css";
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", Helvetica, sans-serif;
  box-sizing: border-box;
}

*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 10vh;
}

body {
  color: var(--black);
}

main {
  margin-top: 10vh;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}

li {
  list-style-type: none;
}

button {
  outline: none;
  border: none;
  background-color: inherit;
}
.marquee-container {
  overflow-y: hidden;
}

select {
  border: none;
  outline: none;
}
select:valid {
  color: var(--gold);
}
select option:hover {
  background: var(--gold);
}
