nav {
  background-color: var(--white);
  position: fixed;
  width: 100%;
  left: 0;
  top: 0px;
  padding: 0 60px !important;
  height: var(--navBarHeight);
  z-index: 500;
}

nav.active {
  border: none !important;
  background-color: var(--black);
  z-index: 502;
  transition: background-color 0s linear 0.8s, z-index 1s step-end;
  -webkit-transition: background-color 0s linear 0.8s, z-index 1s step-end;
  -moz-transition: background-color 0s linear 0.8s, z-index 1s step-end;
  -ms-transition: background-color 0s linear 0.8s, z-index 1s step-end;
  -o-transition: background-color 0s linear 0.8s, z-index 1s step-end;
}

nav.active .btn-nav-wrapper,
nav.active .btn-nav,
nav.active .main-logo,
nav.active span {
  animation: visiblity0 0.8s;
  -webkit-animation: visiblity0 0.8s;
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  -ms-animation-delay: 0.8s;
  -o-animation-delay: 0.8s;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
}

.btn-nav {
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 24px;
  letter-spacing: 1.25px;
  font-size: 0.95em;
}

nav.active .line-menu {
  background-color: var(--white) !important;
}

header .btn-nav.active,
.btn-nav:hover {
  color: var(--gold) !important;
}

.btn-nav-wrapper {
  background-color: var(--gray) !important;
  display: flex;
  align-items: center;
  height: 10vh;
  margin: 0 15px 0 27px !important;
}

.wrapper-menu {
  margin-left: 36px;
  width: 18px;
  height: 16px;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 330ms ease-out;
}

.wrapper-menu.open {
  transition-delay: 1s;
  transform: rotate(-45deg);
}

.line-menu {
  background-color: var(--black) !important;
  border-radius: 5px;
  width: 100%;
  height: 3px;
}

.line-menu.half {
  width: 50%;
  transition: 0.25s ease-in-out;
}

.line-menu.end {
  align-self: flex-end;
}

.open .line-menu.start {
  transform: translateY(6.75px) rotate(90deg);
  -webkit-transform: translateY(6.75px) rotate(90deg);
  -moz-transform: translateY(6.75px) rotate(90deg);
  -ms-transform: translateY(6.75px) rotate(90deg);
  -o-transform: translateY(6.75px) rotate(90deg);
}

.open .line-menu.mid {
  opacity: 0;
}

.open .line-menu.end {
  transform: translateY(-6.75px) rotate(0deg);
  -webkit-transform: translateY(-6.75px) rotate(0deg);
  -moz-transform: translateY(-6.75px) rotate(0deg);
  -ms-transform: translateY(-6.75px) rotate(0deg);
  -o-transform: translateY(-6.75px) rotate(0deg);
}

.open .line-menu.start,
.open .line-menu.mid,
.open .line-menu.end {
  transition-delay: 1s;
}

.open .line-menu.start,
.open .line-menu.end,
.wrapper-menu:hover .line-menu.half {
  width: 100%;
}

.page-transition {
  width: 100%;
  height: 100%;
  position: fixed;
  right: -100%;
  top: 0;
  z-index: 501;
  opacity: 0;
  visibility: hidden;
  transition: all 0.9s cubic-bezier(0.86, 0, 0.07, 1) 0.1s;
  -ms-transition: all 0.9s cubic-bezier(0.86, 0, 0.07, 1) 0.1s;
  -o-transition: all 0.9s cubic-bezier(0.86, 0, 0.07, 1) 0.1s;
  -webkit-transition: all 0.9s cubic-bezier(0.86, 0, 0.07, 1) 0.1s;
  -moz-transition: all 0.9s cubic-bezier(0.86, 0, 0.07, 1) 0.1s;
}

.page-transition .layer {
  width: 0;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--black);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 0.9s ease;
  -ms-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  -webkit-transition: all 0.9s ease;
  -moz-transition: all 0.9s ease;
}

.page-transition.active {
  opacity: 1;
  visibility: visible;
  right: 0;
  transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}

.page-transition.active .layer {
  width: 100%;
  transition: all 0.5s ease 0.3s;
  -ms-transition: all 0.5s ease 0.3s;
  -o-transition: all 0.5s ease 0.3s;
  -webkit-transition: all 0.5s ease 0.3s;
  -moz-transition: all 0.5s ease 0.3s;
}
nav .language {
  color: var(--gold);
  letter-spacing: 2px;
  font-size: 15.5px;
}
header .nav-mini {
  margin-bottom: 100%;
}

@media screen and (max-width: 962px) {
  nav .main-logo img {
    width: 30%;
  }
}

@media screen and (max-width: 768px) {
  nav {
    padding: 0 30px !important;
  }
}

@media screen and (max-width: 568px) {
  nav .main-logo img {
    width: 40%;
  }
}
