@keyframes topMove {
  0% {
    top: -90px;
  }

  100% {
    top: 0px;
  }
}

@keyframes visiblity0 {
  0% {
    visibility: visible;
  }

  20% {
    visibility: hidden;
  }

  100% {
    visibility: hidden;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    left: 100%;
  }

  to {
    left: 0%;
    opacity: 1;
  }
}

@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes left-arrow {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(5px, 0);
  }
}

@keyframes colorSlide {
  0% {
    background-color: #b78b39;
  }
  25% {
    background-color: #c5953d;
  }
  50% {
    background-color: #daa645;
  }
  75% {
    background-color: #d4a143;
  }
  100% {
    background-color: #ecb44b;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  100% {
    top: 47%;
  }
  50% {
    top: 53%;
  }
}


@keyframes bounceLeft {
  0%,
  100% {
    left: 47%;
  }
  50% {
    left: 53%;
  }
}
