.wrapper2 {
  --color: #1f242d;
  --color-invert: #fff;
  --clip-path: circle(30%);
  --clip-path-hover: circle(40%);
  --clip-path-clicked: circle(100vw at center);
  --duration: 0.4s;
  --timing-function: ease;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.the-checkbox {
  opacity: 0;
}

.hot-deals .page-title {
  font-weight: normal;
}
.wrapper2 .video {
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: var(--clip-path);
  transition: clip-path var(--duration) var(--timing-function);
}
.wrapper2 .video video {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.wrapper2 .text {
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.2px;
  opacity: var(--opacity, 1);
  transition: opacity 0.3s var(--timing-function) 0.2s;
  color: white;
}

.wrapper2 .text > span::before,
.wrapper2 .text > span::after {
  content: attr(data-text);
}
.wrapper2 .text > span::before {
  color: white;
}
.wrapper2 .text > span::after {
  color: var(--color-invert);
  clip-path: var(--clip-path);
  transition: clip-path var(--duration) var(--timing-function);
  position: absolute;
  left: 0;
}
.wrapper2 input {
  width: 220px;
  height: 40px;
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 40px;
  outline: none;
  z-index: 2;
  appearance: none;
  cursor: pointer;
}
.wrapper2 input:focus {
  outline: 0;
}
.wrapper2 input:hover ~ .video {
  clip-path: var(--clip-path-hover);
}

.wrapper2 input:hover ~ .text::after {
  clip-path: var(--clip-path-hover);
}
.wrapper2 input:hover ~ .text > span::after {
  clip-path: var(--clip-path-hover);
}
.wrapper2 input:checked {
  width: 100%;
  height: 100%;
  border-radius: 0;
}
.wrapper2 input:checked ~ .video {
  clip-path: var(--clip-path-clicked);
}
.wrapper2 input:checked ~ .text {
  --opacity: 0;
  transition: opacity 0.3s var(--timing-function);
}
.wrapper2 input:checked ~ .text::after {
  clip-path: var(--clip-path-clicked);
}
.wrapper2 input:checked ~ .text > span::after {
  clip-path: var(--clip-path-clicked);
}



.hot-deals .title-text .has_proven_text{
  color: white;
  font-size: 1.6rem;
}

@media (max-width: 769px) {

  .hot-deals .wrapper_video{
    margin-top: -6px;
    margin-left: 30px;
    width: 327px;
  }
  
}



@media (max-width: 568px) {
  
  .hot-deals .title-text .has_proven_text{
    color: white;
    font-size: 1.2rem;
    text-align: center;
  }

  .hot-deals .has_proven_text_ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
      }
/*   .hot-deals .has_proven_text_ul .page-title{
display: flex;
justify-content: center;  } */


 .wrapper2{
 margin-top: -50px;
display: flex;
justify-content: center; 
}

.hot-deals .wrapper_video{
  margin-top: -12px;
 margin-left: 40px;
 width: 250px;
}


.hot-deals .text{
  font-size: 12px;
}

}

@media (max-width: 400px) {

  .hot-deals .wrapper_video{
   margin-left: 50px;
  }
  
}

