.carousel {
  width: 100%;
}

.carousel-item .carousel-item-text {
  position: absolute;
  top: 50%;
  width: 300px;
  transform: translate(-50%, -50%) !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -moz-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  -o-transform: translate(-50%, -50%) !important;
  transition: left 0.8s ease-out;
  -webkit-transition: left 0.8s ease-out;
  -moz-transition: left 0.8s ease-out;
  -ms-transition: left 0.8s ease-out;
  -o-transition: left 0.8s ease-out;
  left: 135%;
  visibility: hidden;
}

.slider_img_is_mak{
  width: 120%;
}

.carousel-item .carousel-item-text .text-content {
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 1.2;
  font-size: 60px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: none !important;
  opacity: 0.6;
}
.carousel-control-prev-icon img,
.carousel-control-next-icon img {
  width: 50px;
}

.carousel-control-prev-icon:active,
.carousel-control-next:active {
  opacity: 0.1 !important;
}

.carousel-item .second-image.mini {
  width: 40vw;
}
.carousel-item.active .second-image.mini {
  left: 6.5vw;
}
.carousel-item.active .second-image {
  left: 10vw;
}
.carousel-item .second-image,
.carousel-item.active .carousel-item-text {
  width: 35vw;
}

.slide-text {
  color: gray;
  writing-mode: vertical-lr;
  letter-spacing: 5px;
}

.circle-button {
  margin: 9px;
  background-color: transparent;
  color: inherit;
  transition: all 0.5s;
  border: 2px solid gray !important;
}
.circle-button.active {
  background-color: gray !important;
}

.carousel-item {
  position: relative !important;
}
.carousel-item .second-image {
  visibility: hidden;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  position: absolute;
  left: -100vw;
  top: 50%;
  transform: translateY(-50%);
  transition: left 0.85s ease-out;
  -webkit-transition: left 0.85s ease-out;
  -moz-transition: left 0.85s ease-out;
  -ms-transition: left 0.85s ease-out;
  -o-transition: left 0.85s ease-out;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.carousel-item.active .carousel-item-text {
  left: 75vw !important;
  visibility: visible;
}

.carousel-item.active .second-image {
  visibility: visible;
  left: 12vw;
}

@media screen and (min-width: 1400px) {
  .carousel-item.active .carousel-item-text {
    width: 30vw !important;
    left: 75vw !important;
    visibility: visible;
  }

  .carousel-item.active .second-image {
    left: 3vw;
  }
}

@media (min-aspect-ratio: 16/9) {
  .carousel-item .second-image,
  .carousel-item .carousel-item-text {
    top: 45vh;
  }
  .carousel {
    height: 90vh;
  }
  .carousel .carousel-indicators .circle-button {
    width: 15px !important;
    height: 15px !important;
    margin: 8px;
  }
}

@media screen and (min-width: 1200px) {
  .carousel .carousel-indicators {
    position: absolute;
    top: 20%;
    height: 80%;
    right: 80% !important;
    left: auto !important;
    transform: translateY(-3%);
    -webkit-transform: translateY(-3%);
    -moz-transform: translateY(-3%);
    -ms-transform: translateY(-3%);
    -o-transform: translateY(-3%);
  }
  .carousel .carousel-indicators .circle-button {
    width: 9px !important;
    height: 9px !important;
    margin: 8px;
  }
  .carousel-item .second-image.mini {
    width: 45vw;
  }
  .carousel-item.active .second-image.mini {
    left: 7.5vw;
  }
  .carousel-item.active .second-image {
    left: 14vw;
  }
  .carousel-item .second-image,
  .carousel-item.active .carousel-item-text {
    width: 35vw;
  }
}
@media screen and (max-width: 1200px) {
  .carousel {
    height: auto;
  }

  .carousel-item.active .second-image {
    top: 50%;
  }
  .carousel-control-prev-icon img,
  .carousel-control-next-icon img {
    width: 35px;
  }

  .carousel .carousel-item .carousel-item-text {
    top: 50%;
    width: 350px;
  }

  .carousel .carousel-indicators .circle-button {
    width: 5px !important;
    height: 5px !important;
    margin: 4px;
  }
  .carousel-item.active .carousel-item-text {
    width: 350px !important;
  }
}

@media screen and (max-width: 992px) {
  .carousel-item.active .carousel-item-text {
    width: 250px !important;
  }
}
@media screen and (max-width: 768px) {
  .carousel-item.active .carousel-item-text {
    width: 175px !important;
  }
}
@media screen and (max-width: 576px) {
  .carousel-control-prev-icon img,
  .carousel-control-next-icon img {
    width: 25px !important;
  }
}
